import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import get from 'lodash.get';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import useEcologiDataHook from '../hooks/useEcologiDataHook';

import Hero from '../components/Hero/Hero';
import HorizontalRule from '../components/HorizontalRule/HorizontalRule';
import DonutGraph from '../components/DonutGraph/DonutGraph';
import Decorator from '../components/Decorator/Decorator';
import QuoteBlock from '../components/QuoteBlock/QuoteBlock';
import IconLeaf from '../components/Icons/IconLeaf';
import ProjectCallout from '../components/ProjectCallout/ProjectCallout';
import Frame from '../components/Frame/Frame';
import ImageTile, { ImageTileProps } from '../components/ImageTile/ImageTile';
import LinkButton from '../components/Buttons/LinkButton/LinkButton';
import StatBlock from '../components/StatBlock/StatBlock';

import ecologiLogo from '../images/ecologi--white.svg';
import greatGreenWallLogo from '../images/great-green-wall.png';
import carbonFootprintLogo from '../images/carbon-footprint--white.svg';
import RichTextBlock from '../components/RichTextBlock/RichTextBlock';
import Seo from '../components/Seo/Seo';

interface CarbonManifestoProps {
    data: {
        contentfulCarbonManifesto: {
            title: string,
            ecologiDescription: RenderRichTextData<ContentfulRichTextGatsbyReference>,
            ecologiImages: {
                title: string,
                image: ImageTileProps['imageData'],
                description?: string,
                link?: string,
            }[],
            greatGreenWallDescription: RenderRichTextData<ContentfulRichTextGatsbyReference>,
            greatGreenWallImage: ImageTileProps['imageData'],
            carbonFootprintDescription: RenderRichTextData<ContentfulRichTextGatsbyReference>,
            carbonFootprintImages: {
                title: string,
                image: ImageTileProps['imageData'],
                description?: string,
                link?: string,
            }[],
        }
    }
}

const CarbonManifestoPage = ({ data }: CarbonManifestoProps): JSX.Element => {
    const {
        ecologiDescription,
        ecologiImages,
        greatGreenWallDescription,
        greatGreenWallImage,
        carbonFootprintDescription,
        carbonFootprintImages,
        title: pageTitle,
    } = data.contentfulCarbonManifesto;

    const ecologiApiData = useEcologiDataHook();

    if (!ecologiApiData) {
        // TODO: Maybe Add a small loading state?
    }

    const trees = get(ecologiApiData, 'trees', '-');
    const carbonOffset = get(ecologiApiData, 'carbonOffset', '-');
    const monthsActive = get(ecologiApiData, 'monthsActive', '-');

    return (
        <Fragment>
            <Seo title={pageTitle} />
            <Hero height="small">
                <Hero.Body containerWidth="default" padding="medium">
                    <div className="space-y-5">
                        <div className="space-y-1 max-w-0.5 m-auto text-center">
                            <Hero.Title><span className="gold">Our carbon manifesto</span><IconLeaf stroke="bronze-400" size="32px" /></Hero.Title>
                            <QuoteBlock
                                author=""
                                quote="The UK&lsquo;s building industry is responsible for a significant portion of the country&lsquo;s carbon footprint. We want to help change that;  We&lsquo;re involved in a number of carbon reduction and offsetting projects worldwide."
                            />
                        </div>
                        <HorizontalRule color="gold-gradient" />
                        <Frame layout="two-col" tag="section" breakpoint="20rem">
                            <div className="space-y-1">
                                <p className="text-paragraph">The building industry is currently responsible for 25% of total UK greenhouse gas emissions (buildings and infrastructure). If surface transport (vehicle emissions) is included within that scope, the total share of UK emissions increases to 42%.</p>
                                <span className="inline-block text-metadata">Source: UK Green Building Council | Whole Life Carbon Roadmap</span>
                            </div>
                            <div className="flex justify-center">
                                <DonutGraph />
                            </div>
                        </Frame>
                        <HorizontalRule color="gold-gradient" />
                    </div>

                </Hero.Body>
            </Hero>
            <div className="contain containMedium space-y-5">
                <div className="text-center space-y-1">
                    <h2 className="text-subtitle max-w-0.25 m-auto">We&rsquo;re proud to support several projects worldwide</h2>
                    <Decorator />
                </div>
                <div className="space-y-7">
                    <Frame layout="two-col">
                        <div className="flex items-center">
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <img src={ecologiLogo} alt="Ecologi" width="120px" />
                                    <RichTextBlock content={ecologiDescription} />
                                </div>
                                <StatBlock
                                    data={
                                        [
                                            {
                                                figure: monthsActive,
                                                label: 'months climate positive'
                                            },
                                            {
                                                figure: carbonOffset,
                                                label: 'tonnes of CO2 offset this year'
                                            },
                                            {
                                                figure: trees,
                                                label: 'trees planted'
                                            }
                                        ]
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-center items-center">
                            <div className="ecologi-images">
                                <ImageTile imageData={ecologiImages[0].image} childrenPosition="at-bottom" gradient="to-top">
                                    <span className="text-subheading">Planting forest gardens in Uganda</span>
                                </ImageTile>
                                <ImageTile imageData={ecologiImages[1].image} childrenPosition="at-bottom" gradient="to-top">
                                    <span className="text-subheading">Preserving Amazonian rainforest in Brazil</span>
                                </ImageTile>
                            </div>
                        </div>
                    </Frame>
                    <Frame layout="two-col">
                        <div className="flex items-center">
                            <div className="space-y-2">
                                <img src={greatGreenWallLogo} alt="Great Green Wall" width="120px" />
                                <RichTextBlock content={greatGreenWallDescription} />
                                <LinkButton href="/" label="View the project" bordered />
                            </div>
                        </div>
                        <div>
                            <ImageTile imageData={greatGreenWallImage} childrenPosition="at-bottom" gradient="to-top">
                                <span className="text-subheading">Growing a new world wonder across the entire width of Africa.</span>
                            </ImageTile>
                        </div>
                    </Frame>
                    <div className="space-y-5">
                        <Frame layout="two-col">
                            <div className="space-y-1">
                                <img src={carbonFootprintLogo} alt="Carbon footprint standard" width="80px" />
                                <RichTextBlock content={carbonFootprintDescription} />
                            </div>
                            <div className="flex items-center">
                                <StatBlock
                                    data={
                                        [
                                            {
                                                figure: monthsActive,
                                                label: 'months climate positive'
                                            },
                                            {
                                                figure: trees,
                                                label: 'trees planted'
                                            }
                                        ]
                                    }
                                />
                            </div>
                        </Frame>
                        <Frame layout="three-col" gap={3}>
                            {
                                carbonFootprintImages.map((image) => (
                                    <ImageTile
                                        key={image.title}
                                        imageData={image.image}
                                        childrenPosition="at-bottom"
                                        gradient="to-top"
                                    >
                                        <Link to={(image.link) ? image.link : '/'} className="text-subheading pseudo-link">
                                            <span className="text-subheading block">{image.title}</span>
                                            {(image.description) ? <span className="text-metadata">{image.description}</span> : null}
                                        </Link>
                                    </ImageTile>
                                ))
                            }
                        </Frame>
                    </div>
                </div>
                <div className="text-center">
                    <Decorator />
                </div>
            </div>

            <ProjectCallout />
        </Fragment>
    );
};


export const pageQuery = graphql`
  query CarbonManifestoContentQuery {
    contentfulCarbonManifesto {
        title
        carbonFootprintImages {
          id
          image {
            gatsbyImageData(
                width: 1920
            )
            description
          }
          link
          title
          description
        }
        carbonFootprintDescription {
          raw
        }
        greatGreenWallImage {
          gatsbyImageData(
            width: 1920
        )
          description
        }
        greatGreenWallDescription {
          raw
        }
        ecologiImages {
          image {
            gatsbyImageData(
                width: 1920
            )
            description
          }
          description
          link
          title
        }
        ecologiDescription {
          raw
        }
      }
  }
`;

export default CarbonManifestoPage;
