import React from 'react';

import * as styles from './Frame.module.scss';

const LayoutTypes = {
    'two-to-one': styles.FrameTwoToOne,
    'three-to-two': styles.FrameThreeToTwo,
    'two-col': styles.FrameTwoCol,
    'three-col': styles.FrameThreeCol
};

const GapTypes = {
    1: 'gap-1',
    3: 'gap-3',
    5: 'gap-5',
    6: 'gap-6'
};

interface FrameProps {
    breakpoint?: `${string}px`|`${string}rem`,
    children: React.ReactNode,
    gap?: keyof typeof GapTypes,
    layout?: keyof typeof LayoutTypes,
    tag?: 'div'|'header'|'section'|'article'
}

const Frame = ({
    breakpoint, children, gap = 5, layout = 'two-col', tag: Tag = 'div'
}: FrameProps): JSX.Element => {
    const breakpointWidth = { '--breakpoint-width': breakpoint } as React.CSSProperties;

    return (
        <Tag className={`${styles.Frame} ${LayoutTypes[layout]} ${GapTypes[gap]}`} style={breakpointWidth}>
            {children}
        </Tag>
    );
};

export default Frame;

// Think we might need two implementations of these structures;

// Flex frame - accepts a layout and gap.
// Layout corresponds to two flex-basis values

// Grid frame - only for same-column layouts, however the break value cannot
// be larger than our minimum viewport width, meaning setting larger column
// widths up can be troublesome
