import React from 'react';

import * as styles from './DonutGraph.module.scss';

const DonutGraph = (): JSX.Element => (
    <div className="flex gap-2 items-center">
        <svg width="170" height="170" viewBox="0 0 170 170" fill="none">
            <path d="M85.2792 1.00048C38.8875 0.846116 1.15453 38.3288 1.00016 84.7205C0.845795 131.112 38.3285 168.845 84.7202 169C131.112 169.154 168.845 131.671 168.999 85.2795C169.154 38.8879 131.671 1.15485 85.2792 1.00048ZM84.7761 152.2C47.6628 152.076 17.6766 121.89 17.8001 84.7764C17.9235 47.6631 48.11 17.6769 85.2233 17.8004C122.337 17.9239 152.323 48.1103 152.199 85.2236C152.076 122.337 121.889 152.323 84.7761 152.2Z" fill="#2D3D5F" />
            <path d="M85.2792 1.00048C103.921 1.06251 122.013 7.32396 136.704 18.7987C151.396 30.2734 161.854 46.3093 166.43 64.3809C171.006 82.4525 169.44 101.533 161.98 118.617C154.519 135.701 141.588 149.818 125.222 158.744L117.177 143.995C130.27 136.854 140.615 125.561 146.584 111.893C152.552 98.2262 153.804 82.962 150.144 68.5047C146.483 54.0475 138.117 41.2187 126.364 32.0389C114.61 22.8592 100.137 17.85 85.2233 17.8004L85.2792 1.00048Z" fill="#995829" />
            <path d="M85.2792 1.00048C96.3102 1.03719 107.226 3.24625 117.403 7.50153C127.58 11.7568 136.82 17.975 144.594 25.801C152.368 33.627 158.525 42.9077 162.712 53.113C166.9 63.3184 169.036 74.2485 168.999 85.2795L152.199 85.2236C152.229 76.3988 150.52 67.6547 147.17 59.4904C143.82 51.3261 138.894 43.9016 132.675 37.6408C126.456 31.38 119.064 26.4054 110.922 23.0012C102.781 19.597 94.0481 17.8297 85.2233 17.8004L85.2792 1.00048Z" fill="#BE997E" />
        </svg>
        <ul className={styles.DonutGraphLabels}>
            <li className="space-y-1">
                <figure className="text-figure text-bronze-400">25%</figure>
                <span className="text-smallcaps">Buildings &amp; infrastructure</span>
            </li>
            <li className="space-y-1">
                <figure className="text-figure text-bronze-400">17%</figure>
                <span className="text-smallcaps">Vehicle emissions</span>
            </li>
        </ul>
    </div>
);

export default DonutGraph;
